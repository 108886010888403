import { createAction, props } from '@ngrx/store';
import { CustomerPortal, CreateCustomerPortal, CustomerPortalFeedback } from '../customer-portal.model';

export const LoadCustomerPortals = createAction(
  '[CustomerPortal] Load Customer Portals',
  props<{ objectId: string }>(),
);
export const LoadCustomerPortalsByCustomer = createAction(
  '[CustomerPortal] Load Customer Portals By Customer',
  props<{ customerId: string }>(),
);
export const CustomerPortalsLoaded = createAction(
  '[CustomerPortal] Customer Portals Loaded',
  props<{ payload: CustomerPortal[] }>(),
);
export const CustomerPortalsLoadingFailed = createAction('[CustomerPortal] Customer Portals Loading Failed');


export const LoadCustomerPortalFeedback = createAction(
  '[CustomerPortal] Load Customer Portal Feedback',
  props<{ objectId: string }>(),
);
export const CustomerPortalFeedbackLoaded = createAction(
  '[CustomerPortal] Customer Portal Feedback Loaded',
  props<{ payload: CustomerPortalFeedback[] }>(),
);
export const CustomerPortalFeedbackLoadingFailed = createAction('[CustomerPortal] Customer Portal Feedback Loading Failed');

export const LoadCustomerPortalDetails = createAction(
  '[CustomerPortal] Load Customer Portal Details',
  props<{ objectId: string; id: string }>(),
);
export const CustomerPortalDetailsLoaded = createAction(
  '[CustomerPortal] Customer Portal Details Loaded',
  props<{ payload: CustomerPortal }>(),
);
export const CustomerPortalDetailsLoadingFailed = createAction(
  '[CustomerPortal] Customer Portal Details Loading Failed'
);

export const SaveCustomerPortal = createAction(
  '[CustomerPortal] Save Customer Portal',
  props<{ data: CreateCustomerPortal; isFinal?: boolean }>(),
);
export const SaveCustomerPortalSucceded = createAction(
  '[CustomerPortal] Succeeded To Create And Save Customer Portal',
  props<{ showToast: boolean }>(),
);
export const SaveCustomerPortalFailed = createAction(
  '[CustomerPortal] Failed To Create And Save Customer Portal',
  props<{ showToast: boolean }>(),
);

export const SendCustomerPortalEmail = createAction(
  '[CustomerPortal] Send Customer Portal Email',
  props<{
    objectId: string;
    id: string;
    emailData: any;
  }>(),
);

export const DeleteCustomerPortal = createAction(
  '[CustomerPortal] Delete Customer Portal',
  props<{ id: string }>()
);

export const DeleteCustomerPortalSucceded = createAction(
  '[CustomerPortal] Customer Portal Deleted',
  props<{ id: string }>()
);

export const DeleteCustomerPortalFailed = createAction('[CustomerPortal] Customer Portal Delete Failed');
