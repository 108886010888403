import { createReducer, on } from '@ngrx/store';
import { CustomerActions } from './action-types';
import {CombinedCustomer, CustomerWithContact} from '../customer.model';
import { ListData } from 'src/app/common-models/list-data';

export interface State {
  allCustomersListData: {
    list: ListData<CombinedCustomer>
    allCustomersLoading: boolean;
  }
  customers: {
    list: ListData<CustomerWithContact>;
    loading: boolean;
  };
  customerDetails: {
    data?: CustomerWithContact;
    loading: boolean;
    hasError: boolean;
  };
  customerDuplicationCheck: {
    loading: boolean;
    hasUpdate: boolean;
  };
}

export const initialState: State = {
  allCustomersListData: {
    list: {
      data: [],
      metadata: {
        total: 0,
      },
    },
    allCustomersLoading: false,
  },
  customers: {
    list: {
      data: [],
      metadata: {
        total: 0,
      },
    },
    loading: false,
  },
  customerDetails: {
    data: undefined,
    loading: false,
    hasError: false,
  },
  customerDuplicationCheck: {
    loading: false,
    hasUpdate: false,
  },
};

const customerReducer = createReducer(
  initialState,

  on(CustomerActions.LoadAllCustomers, (state) => ({
    ...state,
    allCustomersListData: {
      ...state.allCustomersListData,
      allCustomersLoading: true,
    },
  })),
  on(CustomerActions.LoadAllCustomersSucceeded, (state, { payload }) => ({
    ...state,
    allCustomersListData: {
      ...state.allCustomersListData,
      allCustomersLoading: false,
      list: payload,
    },
  })),
  on(CustomerActions.LoadAllCustomersFailed, (state) => ({
    ...state,
    allCustomersListData: {
      ...state.allCustomersListData,
      allCustomersLoading: false,
    },
  })),

  on(CustomerActions.LoadCustomers, (state) => ({
    ...state,
    customers: {
      ...state.customers,
      loading: true,
    },
  })),
  on(CustomerActions.LoadCustomersSucceeded, (state, { payload }) => ({
    ...state,
    customers: {
      loading: false,
      list: payload,
    },
  })),
  on(CustomerActions.LoadCustomersFailed, (state) => ({
    ...state,
    customers: {
      ...state.customers,
      loading: false,
    },
  })),

  on(CustomerActions.LoadCustomerDetails, (state) => ({
    ...state,
    customerDetails: {
      data: undefined,
      loading: true,
      hasError: false,
    },
  })),
  on(CustomerActions.LoadCustomerSucceeded, (state, { payload }) => ({
    ...state,
    customerDetails: {
      loading: false,
      data: payload,
      hasError: false,
    },
  })),
  on(CustomerActions.LoadCustomerFailed, (state) => ({
    ...state,
    customerDetails: {
      ...state.customerDetails,
      loading: false,
      hasError: true,
    },
  })),
  on(CustomerActions.StartCustomersDuplicationCheck, (state) => ({
    ...state,
    customerDuplicationCheck: {
      loading: true,
      hasUpdate: false,
    },
  })),
  on(CustomerActions.FinishCustomersDuplicationCheck, (state, { payload }) => ({
    ...state,
    customerDuplicationCheck: {
      loading: false,
      hasUpdate: payload.hasUpdate,
    },
  })),
  on(CustomerActions.ResetCustomersDuplicationCheck, (state) => ({
    ...state,
    customerDuplicationCheck: {
      loading: false,
      hasUpdate: false,
    },
  })),
);

export const reducer = (state: State | undefined, action: any) =>
  customerReducer(state, action);

export const getCustomersList = (state: State) => state.customers.list;
export const getCustomersListData = (state: State) => state.customers.list.data;
export const getCustomersListMetadata = (state: State) => state.customers.list.metadata;
export const getCustomersLoading = (state: State) => state.customers.loading;

export const getCustomerDetails = (state: State) => state.customerDetails.data;
export const getCustomerDetailsLoading = (state: State) => state.customerDetails.loading;
export const getCustomerDetailsHasError = (state: State) => state.customerDetails.hasError;

export const getCustomerDuplicationCheck = (state: State) => state.customerDuplicationCheck;

export const getAllCustomersListData = (state: State) => state.allCustomersListData.list.data;
export const getAllCustomersLoading = (state: State) => state.allCustomersListData.allCustomersLoading;
export const getAllCustomersListMetadata = (state: State) => state.allCustomersListData.list.metadata;

