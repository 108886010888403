import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription, timer } from 'rxjs';
import {
  setEdiRealTokenValidity,
  setJustimmoTokenValidity, setMicrosoftTokenValidity,
  setRemaxTokenValidity,
} from './store/_global/global.actions';
import { AuthService } from './services/auth.service';
import { loadSettings } from './store/settings/settings.actions';
import { StaticFeatureToggles } from '../environments/static-feature-toggles';
import { environment } from '../environments/environment';
import { EdirealService } from 'services/edireal.service';
import { loadProfile } from './store/profile/profile.actions';
import { JustimmoService } from 'services/justimmo.service';
import { LocationService } from 'services/location.service';
import { AccessService } from "services/access-service";
import { RightSidenavService } from 'services/right-sidenav.service';
import { RemaxService } from 'services/remax.service';
import { TeamDataFacade } from 'src/app/domains/team';
import { MicrosoftService } from "services/microsoft.service";
import { TranslationService } from 'src/app/i18n/TranslationService';
import { IntegrationStatusService } from 'services/intergrations.service';
import { NewVersionCheckerService } from "services/check-version";
import { ObjectDataFacade } from '@domains/object';
import { PropertyCustomStepService } from 'services/property-custom-step.service';

const MINUTE = 1000 * 60;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _subscription = new Subscription();
  hasFreeVersion$ = this.accessService.hasFreeVersion$;

  events: string[] = [];
  opened!: boolean;
  innerWidth: any;

  isRightSidenavVisible = false;
  isRightSidenavExpanded = false;
  isLeftSidenavVisible = false;
  isLeftSidenavButtonVisible = false;

  isMobile = true;
  windowWidth = window.innerWidth;

  @ViewChild('leftSidenav') leftSidenav!: MatSidenav;
  @ViewChild('rightSidenav') rightSidenav!: MatSidenav;
  @ViewChild('leftSidenavContent') leftSidenavContent!: ElementRef;
  @ViewChild('rightSidenavContent') rightSidenavContent!: ElementRef;

  get feature(): StaticFeatureToggles {
    return environment.staticToggles;
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get showMeineKundenOnMobile(): boolean {
    return this.router.url === '/dashboard';
  }

  get contentWidth(): string {
    const contentWidth = this.windowWidth - (this.leftSidenavContent?.nativeElement.offsetWidth || 0) - (this.rightSidenavContent?.nativeElement.offsetWidth || 0);
    return `${contentWidth}px`;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private store: Store,
    private observer: BreakpointObserver,
    private _teamsDataFacade: TeamDataFacade,
    private _objectDataFacade: ObjectDataFacade,
    private _edirealService: EdirealService,
    private _remaxService: RemaxService,
    private _justimmoService: JustimmoService,
    private _locationService: LocationService,
    private _microsoftService: MicrosoftService,
    private _rightSidenavService: RightSidenavService,
    private _translationService: TranslationService,
    private _integrationStatusService: IntegrationStatusService,
    private _propertyCustomStepService: PropertyCustomStepService,
    public _versionChecker: NewVersionCheckerService,
    public accessService: AccessService,
  ) {
    this._translationService.init();

    this._locationService.init();

    this.observer.observe('(min-width: 1024px)').subscribe((result) => {
      this.isMobile = !result.matches;
      this._updateNavigationElements();
    });

    this.observer.observe('(min-width: 1660px)').subscribe((result) => {
      const isTablet = !result.matches;
      this.isRightSidenavExpanded = !isTablet;
    });

    this._rightSidenavService.sidenavTriggerClose$.subscribe(() => {
      this.rightSidenav.close();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this._versionChecker.checkForUpdate();
    if (this.authService.isLoggedIn) {
      this.store.dispatch(loadSettings());
      this.store.dispatch(loadProfile());
      this.checkEdiRealTokenValidity();
      this.checkRemaxTokenValidity();
      this.checkJustimmoTokenValidity();
      this.checkSyncCustomersState();
      this.checkMicrosoftTokenValidity();
      this._teamsDataFacade.loadTeams();
      this._objectDataFacade.loadCommonPropertyExtensionConfig();
      this._objectDataFacade.loadOnOfficeDocumentConfig();

      this._propertyCustomStepService.initCustomStepsGeneration();
    }

    if (this.location.path() === '') {
      if (this.authService.isLoggedIn) {
        this.router.navigateByUrl('/post-login');
      } else {
        this.router.navigateByUrl('/auth');
      }
    }
  }

  ngAfterViewInit(): void {
    this._integrationStatusService.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  checkEdiRealTokenValidity(): void {
    this._subscription.add(
      timer(0, MINUTE).pipe(
        switchMap(() => this._edirealService.checkTokenValidity()),
        tap((isTokenValid) => {
          this.store.dispatch(setEdiRealTokenValidity({ isTokenValid }));
        }),
        takeWhile((isTokenValid) => isTokenValid),
      ).subscribe()
    );
  }

  checkRemaxTokenValidity(): void {
    this._subscription.add(
      timer(0, MINUTE).pipe(
        switchMap(() => this._remaxService.checkTokenValidity$()),
        tap((isTokenValid) => {
          this.store.dispatch(setRemaxTokenValidity({ isTokenValid }));
        }),
        takeWhile((isTokenValid) => isTokenValid),
      ).subscribe()
    );
  }

  checkJustimmoTokenValidity(): void {
    this._subscription.add(
      timer(0, MINUTE).pipe(
        switchMap(() => this._justimmoService.checkTokenValidity$()),
        tap((isTokenValid) => {
          this.store.dispatch(setJustimmoTokenValidity({ isTokenValid }));
        }),
        takeWhile((isTokenValid) => isTokenValid),
      ).subscribe()
    );
  }

  checkMicrosoftTokenValidity(): void {
    this._subscription.add(
      timer(0, MINUTE).pipe(
        switchMap(() => this._microsoftService.checkTokenValidity$()),
        tap((isTokenValid) => {
          this.store.dispatch(setMicrosoftTokenValidity({ isTokenValid }));
        }),
        takeWhile((isTokenValid) => isTokenValid),
      ).subscribe()
    );
  }

  checkSyncCustomersState(): void {
    const sub = this._edirealService.checkCustomersSync();

    if (sub) {
      this._subscription.add(sub);
    }
  }

  showLeftSideNav(): void {
    this.leftSidenav.open();
  }

  showRightSideNav(): void {
    this.rightSidenav.open();
  }

  onMenuItemClicked(): void {
    if (this.isMobile) {
      this.leftSidenav.close();
    }
  }

  onLogoutClicked(): void {
    this.authService.logout();
  }

  private _updateNavigationElements(): void {
    this.isRightSidenavVisible = this.isLoggedIn && !this.isMobile;
    this.isLeftSidenavVisible = this.isLoggedIn && !this.isMobile;
    this.isLeftSidenavButtonVisible = this.isLoggedIn && this.isMobile;
  }
}
