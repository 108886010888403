import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { first, map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client';

import {CustomerWithContact, CustomerCreation, CustomerDetails, CombinedCustomer} from './customer.model';
import { Country } from '@domains/viewing-pass';
import { PagingOptions } from 'src/app/common-models/paging-options';
import { ListData } from 'src/app/common-models/list-data';

@Injectable({ providedIn: 'root' })
export class CustomerApiFacade {
  constructor(private readonly apollo: Apollo) { }


  createCustomer$(data: CustomerCreation): Observable<CustomerWithContact> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation CreateCustomer($customer: CreateCustomer!) {
          customer(customer: $customer) {
            ...customerFragment
          }
        }
        ${customerFragment}
      `,
        variables: {
          customer: data,
        },
      })
      .pipe(map((result: any) => result.data.customer));
  }

  searchCustomer$(firstname = '', lastname = ''): Observable<CustomerWithContact[]> {
    return this.apollo.watchQuery({
      query: gql`
        query SearchCustomers($firstname: String!, $lastname: String!) {
          searchCustomers(firstname: $firstname, lastname: $lastname) {
            id
            title
            firstname
            lastname
            externalId
            isConsumer
            contact {
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
                longitude
                latitude
              }
              phone
              email
            }
          }
        }
      `,
      variables: {
        firstname,
        lastname,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(
      map((result: ApolloQueryResult<any>) => result.data.searchCustomers)
    );
  }

  getRelatedPropertyCustomers$(propertyId: string): Observable<CombinedCustomer[]> {
    return this.apollo.query({
      query: gql`
        query GetRelatedPropertyCustomers($propertyId: String!) {
          getRelatedPropertyCustomers(propertyId: $propertyId) {
            id
            title
            firstname
            lastname
            externalId
            type
            companyNumber
            contact {
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
                longitude
                latitude
              }
              phone
              email
            }
          }
        }
      `,
      variables: {
        propertyId
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: ApolloQueryResult<any>) => result.data.getRelatedPropertyCustomers)
    );
  }

  getCustomers$(
    pagingOptions: PagingOptions,
    searchText?: string
  ): Observable<ListData<CustomerWithContact>> {
    return this.apollo.watchQuery({
      query: gql`
        query GetCustomers($pagingOptions: PagingOptions!, $searchText: String) {
          customers(pagingOptions: $pagingOptions, searchText: $searchText) {
            data {
              id
              title
              firstname
              lastname
              externalId
              isConsumer
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              dataPrivacy {
                propertyInformation
                newsletter
                questionnaire
                sellingLead
                interestInFinanceOffer
                faggAgreed
              }
            }
            metadata {
              total
            }
          }
        }
      `,
      variables: {
        pagingOptions,
        searchText,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(
      map((result: ApolloQueryResult<any>) => result.data.customers)
    );
  }

  loadAllCustomers$(
    pagingOptions: PagingOptions,
    searchText?: string
  ): Observable<ListData<CombinedCustomer>> {
    return this.apollo.watchQuery({
      query: gql`
        query GetAllCustomers($pagingOptions: PagingOptions!, $searchText: String) {
          allCustomers(pagingOptions: $pagingOptions, searchText: $searchText) {
            data {
              id
              title
              firstname
              lastname
              externalId
              companyNumber
              type
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              dataPrivacy {
                propertyInformation
                newsletter
                questionnaire
                sellingLead
                interestInFinanceOffer
                faggAgreed
              }
            }
            metadata {
              total
            }
          }
        }
      `,
      variables: {
        pagingOptions,
        searchText,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(
      map((result: ApolloQueryResult<any>) => result.data.allCustomers)
    );
  }

  getCustomerById$(id: string): Observable<CustomerDetails> {
    return this.apollo.query({
      query: gql`
        query GetCustomerDetails($customerId: String!) {
          customer(id: $customerId) {
            id
            title
            firstname
            lastname
            birthdate
            nationality
            externalId
            isConsumer
            contact {
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
                longitude
                latitude
              }
              email
              phone
            }
            pep {
              isPep
              wasPepLastYear
              isFamilyMemberPep
              isKnownToBeCloseToPep
              firstName
              lastName
            }
            moneyLaundering {
              askForMoneyLaundering
              residentPerson
              investmentIncome
              estateSales
              endowment
              inheritance
              savings
              employment
              investments
              pension
              loan
              other
              otherDetails
            }
            IDImages {
              id
              storageUrl
              type
              description
            }
            dataPrivacy {
              propertyInformation
              newsletter
              questionnaire
              sellingLead
              interestInFinanceOffer
              faggAgreed
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId: id,
      },
    }).pipe(map((result: ApolloQueryResult<any>) => result.data.customer));
  }

  getExternalCustomerById$(id: string): Observable<CustomerDetails> {
    return this.apollo.query({
      query: gql`
        query GetExternalCustomerDetails($customerId: String!) {
          externalCustomer(id: $customerId) {
            title
            firstname
            lastname
            birthdate
            nationality
            contact {
              address {
                city
                zipCode
                streetName
              }
              email
              phone
            }
            externalId
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId: id,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const customerData = result.data.externalCustomer;
        customerData.id = customerData.externalId;

        return customerData;
      })
    );
  }

  importEdirealCustomer$(data: CustomerCreation): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation ImportEdiRealCustomer($customer: CreateCustomer!) {
          importEdirealCustomer(customer: $customer)
        }
      `,
        variables: {
          customer: data,
        },
      })
      .pipe(map((result: any) => result.data.importEdirealCustomer));
  }

  getCountries$(): Observable<Country[]> {
    return this.apollo.watchQuery({
      query: gql`
        query GetCountries {
          countries {
            fullName
            code
          }
        }
      `,
    }).valueChanges.pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data.countries),
    );
  }

  deleteCustomer(customerId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeleteCustomer($customerId: String!) {
          deleteCustomer(customerId: $customerId)
        }
      `,
      variables: {
        customerId,
      },
    }).pipe(
      map((result: any) => result.data.deleteCustomer)
    );
  }

  downloadCustomerData$(): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DownloadCustomerData {
          downloadCustomers
        }
      `,
    }).pipe(
      map((result: any) => result.data.downloadCustomers)
    );
  }

  runCustomerDuplicationCheck$(): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation RunCustomerDuplicationCheck {
          runCustomerDuplicationCheck
        }
      `,
    }).pipe(
      map((result: any) => result.data.runCustomerDuplicationCheck)
    );
  }

}

export const customerFragment = gql`
  fragment customerFragment on Customer {
    id
    title
    firstname
    lastname
    externalId
    isConsumer
    birthdate
    nationality
    contact {
      address {
        country
        city
        zipCode
        streetName
        buildingNumber
        doorNumber
        stairway
      }
      phone
      email
    }
    pep {
      isPep
      wasPepLastYear
      isFamilyMemberPep
      isKnownToBeCloseToPep
      firstName
      lastName
    }
    moneyLaundering {
      askForMoneyLaundering
      residentPerson
      investmentIncome
      estateSales
      endowment
      inheritance
      savings
      employment
      investments
      pension
      loan
      other
      otherDetails
    }
    IDImages {
      id
      storageUrl
      type
      description
    }
    dataPrivacy {
      propertyInformation
      newsletter
      questionnaire
      sellingLead
      interestInFinanceOffer
      faggAgreed
    }
  }
`
