import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client";
import { SigneeSignatureResult } from "@domains/handover-protocol";
import { Apollo, gql } from "apollo-angular";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { AgentCompany } from "src/app/common-models/agent-company";
import {
  BuyingOffer,
  BuyingOfferSellersAcceptance,
  SignedAndCompletedBuyingOffer
} from "./buying-offer.model";
import { mapSignatureDataForGraphQL } from "src/app/helper/mapSignatureDataForGraphQL";

@Injectable({ providedIn: 'root' })
export class BuyingOfferApiFacade {
  constructor(private readonly apollo: Apollo) { }

  saveBuyingOffer$(data: any, ignoreStateCheck = false): Observable<BuyingOffer> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation SaveBuyingOffer($buyingOffer: BuyingOfferInput, $ignoreStateCheck: Boolean) {
            buyingOffer(buyingOffer: $buyingOffer, ignoreStateCheck: $ignoreStateCheck) {
              id
              objectId
              state
              lastVisited
              language
              propertyDetails {
                property {
                  constructionYear
                  constructionYearCa
                  registrationLand
                  cadastralCommunity
                  entryNumber
                  parcelNumber
                  parcelNumbers
                  propertyType
                  propertyTypeNotes
                  shareInOwnership
                  cadastreLabel
                  parkingSlotsLabel
                  complementLabel
                }
                housingSubsidies
                rulesOfUse
                price
                includingAllTaxes
                taxBase
                additionalPrices {
                  name
                  price
                }
                totalCalculatedPrice
              }
              realtyPrice {
                ...realtyPriceFragment
              }
              paymentDetails {
                repayment
                repayments {
                  cadastreNumber
                  takeover
                  obligationType
                  obligationTypeOther
                  pawnee
                  pawneeIBAN
                  repaimentObligation
                  repaimentHolder
                }
                repaimentObligation
                repaimentHolder
                repaimentAmount
                automatedCalculation
                additionalConditions
                additionalConditionsDetails
              }
              drafterOfDeedsInfo {
                contractorId
                contractPartnerId
                autoSendContractPartnerEmail
                name
                address
                phone
                email
                assignment
                additionalCosts
                additionalConditions
                additionalConditionsDetails
              }
              handoverAndConditionBuy {
                handoverOnSigning
                handoverOnMoneyReceived
                handoverOnOther
                handoverDate
                handoverConditionDate
                handoverNotes
                warranty
                warrantyException
                warrantyExceptionDetails
                warrantyExclusion
                needOfRedevelopment
                removeCondition
                removeConditionDetails
                removeResponsibility
                costResponsibility
                leaveCondition
                leaveConditionDetails
                promiseCondition
                promiseConditionDetails
              }
              additionalConditionsBuy {
                additionalConditionsDetails
                expenseAllowanceAmount
                commissionBase
                otherConditionalDetails
                urgentLivingNeed
                useAsPrimaryResidence
              }
              contractTerms {
                contractValidUntil
                alternativeValidity
                restriction
                restrictionDetails
                financeAgreement
                financeRestriction
                financeRestrictionDays
              }
              printSettings {
                hideSellerOnPrint
              }
              buyerType
              buyers {
                customerId
                nationalInsuranceNumber
                askForBankData
                bankData {
                  iban
                  bic
                }
                shares {
                  stake
                  bLnr
                }
              }
              businessBuyer {
                customerId
                shares {
                  stake
                  bLnr
                }
              }
              buyersTrusteeship {
                trusteeship
                trusteeshipOwner
              }
              sellerType
              sellers {
                customerId
                nationalInsuranceNumber
                askForBankData
                bankData {
                  iban
                  bic
                }
                shares {
                  stake
                  bLnr
                }
              }
              businessSeller {
                customerId
                shares {
                  stake
                  bLnr
                }
              }
              sellersAcceptance {
                sellerAcceptance
                changeAcceptanceUntil
                changeDetails
              }
              buyersAcceptanceWithChange {
                buyersAcceptanceWithChange
              }
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
              customerDocuments {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
            }
          }
          ${realtyPriceFragment}
        `,
        variables: {
          buyingOffer: data,
          ignoreStateCheck,
        },
      })
      .pipe(
        first(),
        map((result: any) => ({
          ...result.data.buyingOffer,
          lastVisited: new Date(result.data.buyingOffer?.lastVisited),
        }))
      );
  }

  sendBuyerCustomerPortalNotification$(buyingOfferId: string): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query SendBuyerCustomerPortalNotification($buyingOfferId: String!) {
          sendBuyingOfferBuyerCustomerPortalNotification(buyingOfferId: $buyingOfferId)
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        buyingOfferId,
      },
    }).pipe(first(), map((result: any) => result.data.sendBuyingOfferBuyerCustomerPortalNotification));
  }

  sendSellerOwnerPortalNotification$(buyingOfferId: string): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query SendSellerOwnerPortalNotification($buyingOfferId: String!) {
          sendBuyingOfferSellerOwnerPortalNotification(buyingOfferId: $buyingOfferId)
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        buyingOfferId,
      },
    }).pipe(first(), map((result: any) => result.data.sendBuyingOfferSellerOwnerPortalNotification));
  }

  loadBuyingOffers$(objectId: string): Observable<BuyingOffer[]> {
    return this.apollo.query({
      query: gql`
        query GetBuyingOffers($objectId: String!) {
          buyingOffers(objectId: $objectId) {
            id
            objectId
            state
            lastVisited
            language
            buyerType
            buyers {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
              shares {
                stake
                bLnr
              }
            }
            businessBuyer {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
              shares {
                stake
                bLnr
              }
            }
            sellerType
            sellers {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
              shares {
                stake
                bLnr
              }
            }
            businessSeller {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
              shares {
                stake
                bLnr
              }
            }
            signatureData {
              signedDocumentUrl
            }
            isMasterTemplate
            isFromCustomerPortal
            creator {
              isCreatedByMe
              name
              email
            }
            sellersAcceptance {
              sellerAcceptance
            }
            customerPortal {
              showSellerDecision
            }
            ownerCustomerPortal {
              showBuyerDecision
            }
            isAvailableForOwner
            drafterOfDeedsInfo {
              contractorId
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
      },
    }).pipe(first(), map((result: any) => result.data.buyingOffers));
  }

  loadBuyingOffersByCustomer$(customerId: string): Observable<BuyingOffer[]> {
    return this.apollo.query({
      query: gql`
        query GetBuyingOffersByCustomer($customerId: String!) {
          buyingOffersByCustomer(customerId: $customerId) {
            id
            objectId
            state
            lastVisited
            language
            buyerType
            buyers {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
              shares {
                stake
                bLnr
              }
            }
            businessBuyer {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
              shares {
                stake
                bLnr
              }
            }
            sellerType
            sellers {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
              shares {
                stake
                bLnr
              }
            }
            businessSeller {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
              shares {
                stake
                bLnr
              }
            }
            signatureData {
              signedDocumentUrl
            }
            isMasterTemplate
            isFromCustomerPortal
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            sellersAcceptance {
              sellerAcceptance
            }
            customerPortal {
              showSellerDecision
            }
            ownerCustomerPortal {
              showBuyerDecision
            }
            isAvailableForOwner
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId,
      },
    }).pipe(first(), map((result: any) => result.data.buyingOffersByCustomer));
  }

  updateBuyingOffersAvailability$(objectId: string, offers: any): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
          mutation UpdateBuyingOffersOwnerAvailability(
            $objectId: String!
            $offers: [OfferAvailabilityInput!]
          ) {
            updateBuyingOffersOwnerAvailability(
              objectId: $objectId
              offers: $offers
            )
          }
        `,
      variables: {
        offers,
        objectId,
      },
    }).pipe(
      map((result: any) => result.data.updateBuyingOffersOwnerAvailability)
    );
  }

  getBuyingOfferMasterTemplate$(objectId: string): Observable<BuyingOffer> {
    return this.apollo.query({
      query: gql`
        query GetBuyingOfferMasterTemplate($objectId: String!) {
          buyingOfferMasterTemplate(objectId: $objectId) {
            id
            objectId
            lastVisited
            language
            propertyDetails {
              property {
                constructionYear
                constructionYearCa
                registrationLand
                cadastralCommunity
                entryNumber
                parcelNumber
                parcelNumbers
                propertyType
                propertyTypeNotes
                shareInOwnership
                cadastreLabel
                parkingSlotsLabel
                complementLabel
              }
              housingSubsidies
              rulesOfUse
              price
              includingAllTaxes
              taxBase
              additionalPrices {
                name
                price
              }
              totalCalculatedPrice
            }
            realtyPrice {
              ...realtyPriceFragment
            }
            paymentDetails {
              repayment
              repayments {
                cadastreNumber
                takeover
                obligationType
                obligationTypeOther
                pawnee
                pawneeIBAN
                repaimentObligation
                repaimentHolder
              }
              repaimentObligation
              repaimentHolder
              repaimentAmount
              automatedCalculation
              additionalConditions
              additionalConditionsDetails
            }
            drafterOfDeedsInfo {
              contractorId
              contractPartnerId
              autoSendContractPartnerEmail
              name
              address
              phone
              email
              assignment
              additionalCosts
              additionalConditions
              additionalConditionsDetails
            }
            handoverAndConditionBuy {
              handoverOnSigning
              handoverOnMoneyReceived
              handoverOnOther
              handoverDate
              handoverConditionDate
              handoverNotes
              warranty
              warrantyException
              warrantyExceptionDetails
              warrantyExclusion
              needOfRedevelopment
              removeCondition
              removeConditionDetails
              removeResponsibility
              costResponsibility
              leaveCondition
              leaveConditionDetails
              promiseCondition
              promiseConditionDetails
            }
            additionalConditionsBuy {
              additionalConditionsDetails
              expenseAllowanceAmount
              commissionBase
              otherConditionalDetails
              urgentLivingNeed
              useAsPrimaryResidence
            }
            contractTerms {
              contractValidUntil
              alternativeValidity
              restriction
              restrictionDetails
              financeAgreement
              financeRestriction
              financeRestrictionDays
            }
            printSettings {
              hideSellerOnPrint
            }
            sellerType
            businessSeller {
              customerId
              shares {
                stake
                bLnr
              }
            }
            sellers {
              customerId
              shares {
                stake
                bLnr
              }
            }
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
        }
      }
      ${realtyPriceFragment}
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(first(), map((result: any) => result.data.buyingOfferMasterTemplate));
  }

  loadBuyingOfferDetails$(objectId: string, buyingOfferId: string): Observable<BuyingOffer> {
    return this.apollo.query({
      query: gql`
        query GetBuyingOfferById($objectId: String!, $buyingOfferId: String!) {
          buyingOffer(objectId: $objectId, buyingOfferId: $buyingOfferId) {
            id
            objectId
            lastVisited
            language
            state
            propertyDetails {
              property {
                constructionYear
                constructionYearCa
                registrationLand
                cadastralCommunity
                entryNumber
                parcelNumber
                parcelNumbers
                propertyType
                propertyTypeNotes
                shareInOwnership
                cadastreLabel
                parkingSlotsLabel
                complementLabel
              }
              housingSubsidies
              rulesOfUse
              price
              includingAllTaxes
              taxBase
              additionalPrices {
                name
                price
              }
              totalCalculatedPrice
            }
            realtyPrice {
              ...realtyPriceFragment
            }
            paymentDetails {
              repayment
              repayments {
                cadastreNumber
                takeover
                obligationType
                obligationTypeOther
                pawnee
                pawneeIBAN
                repaimentObligation
                repaimentHolder
              }
              repaimentObligation
              repaimentHolder
              repaimentAmount
              automatedCalculation
              additionalConditions
              additionalConditionsDetails
            }
            drafterOfDeedsInfo {
              contractorId
              contractPartnerId
              autoSendContractPartnerEmail
              name
              address
              phone
              email
              assignment
              additionalCosts
              additionalConditions
              additionalConditionsDetails
            }
            handoverAndConditionBuy {
              handoverOnSigning
              handoverOnMoneyReceived
              handoverOnOther
              handoverDate
              handoverConditionDate
              handoverNotes
              warranty
              warrantyException
              warrantyExceptionDetails
              warrantyExclusion
              needOfRedevelopment
              removeCondition
              removeConditionDetails
              removeResponsibility
              costResponsibility
              leaveCondition
              leaveConditionDetails
              promiseCondition
              promiseConditionDetails
            }
            additionalConditionsBuy {
              additionalConditionsDetails
              expenseAllowanceAmount
              commissionBase
              otherConditionalDetails
              urgentLivingNeed
              useAsPrimaryResidence
            }
            contractTerms {
              contractValidUntil
              alternativeValidity
              restriction
              restrictionDetails
              financeAgreement
              financeRestriction
              financeRestrictionDays
            }
            printSettings {
              hideSellerOnPrint
            }
            buyerType
            buyers {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
              nationalInsuranceNumber
              askForBankData
              bankData {
                iban
                bic
              }
              shares {
                stake
                bLnr
              }
            }
            businessBuyer {
              customerId
              shares {
                stake
                bLnr
              }
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                  }
                  phone
                  email
                }
                customers {
                  id
                  title
                  birthdate
                  firstname
                  lastname
                  nationality
                  contact {
                    address {
                      country
                      city
                      zipCode
                      streetName
                      buildingNumber
                      doorNumber
                      stairway
                      longitude
                      latitude
                    }
                    phone
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                  moneyLaundering {
                    askForMoneyLaundering
                    residentPerson
                    investmentIncome
                    estateSales
                    endowment
                    inheritance
                    savings
                    employment
                    investments
                    pension
                    loan
                    other
                    otherDetails
                  }
                  IDImages {
                    id
                    storageUrl
                    type
                    description
                  }
                }
              }
            }
            buyersTrusteeship {
              trusteeship
              trusteeshipOwner
            }
            sellerType
            sellers {
              customerId
              nationalInsuranceNumber
              askForBankData
              bankData {
                iban
                bic
              }
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
              shares {
                stake
                bLnr
              }
            }
            businessSeller {
              customerId
              shares {
                stake
                bLnr
              }
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                  }
                  phone
                  email
                }
                customers {
                  id
                  title
                  birthdate
                  firstname
                  lastname
                  nationality
                  contact {
                    address {
                      country
                      city
                      zipCode
                      streetName
                      buildingNumber
                      doorNumber
                      stairway
                      longitude
                      latitude
                    }
                    phone
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                  IDImages {
                    id
                    storageUrl
                    type
                    description
                  }
                }
              }
            }
            sellersAcceptance {
              sellerAcceptance
              changeAcceptanceUntil
              changeDetails
            }
            buyersAcceptanceWithChange {
              buyersAcceptanceWithChange
            }
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            customerDocuments {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            signatureData {
              buyers {
                type
                signatureAsSvg
                signedViaTAN
                signatureText
                present
                date
                location
              }
              sellers {
                type
                signatureAsSvg
                signatureText
                present
                date
                location
              }
              signedDocumentUrl
            }
          }
        }
        ${realtyPriceFragment}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        buyingOfferId: buyingOfferId,
      },
    }).pipe(first(), map((result: any) => result.data.buyingOffer));
  }

  deleteBuyingOffer$(buyingOfferId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
          mutation DeleteBuyingOffer($buyingOfferId: String!) {
            deleteBuyingOffer(buyingOfferId: $buyingOfferId)
          }
        `,
      variables: {
        buyingOfferId,
      },
    }).pipe(
      map((result: any) => result.data.deleteBuyingOffer)
    );
  }

  updateBuyingOfferMasterTemplate$(buyingOfferId: string, objectId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
          mutation UpdateBuyingOfferMasterTemplate(
            $objectId: String!
            $buyingOfferId: String!
          ) {
            updateBuyingOfferMasterTemplate(
              objectId: $objectId
              buyingOfferId: $buyingOfferId
            )
          }
        `,
      variables: {
        buyingOfferId,
        objectId,
      },
    }).pipe(
      map((result: any) => result.data.updateBuyingOfferMasterTemplate)
    );
  }

  resetBuyingOffer$(objectId: string, buyingOfferId: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
          mutation ResetBuyingOffer(
            $objectId: String!
            $buyingOfferId: String!
          ) {
            resetBuyingOffer(
              objectId: $objectId
              buyingOfferId: $buyingOfferId
            )
          }
        `,
      variables: {
        buyingOfferId,
        objectId,
      },
    }).pipe(
      map((result: any) => result.data.resetBuyingOffer)
    );
  }

  signAndCompleteBuyingOffer$(
    objectId: string,
    buyingOfferId: string,
    buyersSignatureData: SigneeSignatureResult[],
    sellersSignatureData: SigneeSignatureResult[] | null,
    sellersAcceptance: BuyingOfferSellersAcceptance | null,
    agentCompany: AgentCompany
  ): Observable<SignedAndCompletedBuyingOffer> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SignAndCompleteBuyingOffer(
          $signatureInput: BuyingOfferSignatureInput!
          $agentCompany: AgentCompany!
        ) {
          signAndCompleteBuyingOffer(
            signatureInput: $signatureInput
            agentCompany: $agentCompany
          ) {
            id
            state
            lastVisited
            objectId
            signatureData {
              signedDocumentUrl
            }
          }
        }
      `,
      variables: {
        signatureInput: {
          objectId,
          buyingOfferId,
          sellersAcceptance,
          buyersSignatureData: buyersSignatureData.map((s) => mapSignatureDataForGraphQL(s)),
          sellersSignatureData: sellersSignatureData ?
            sellersSignatureData.map((s) => mapSignatureDataForGraphQL(s)) : null,
        },
        agentCompany,
      },
    })
      .pipe(
        map((result: any) => {
          const untypedBuyingOffer = result.data.signAndCompleteBuyingOffer;

          const buyingOffer = untypedBuyingOffer as SignedAndCompletedBuyingOffer;
          buyingOffer.lastVisited = new Date(buyingOffer.lastVisited);

          return buyingOffer;
        })
      );
  }

  sendBuyingOfferEmail$(
    buyingOfferId: string,
    emailData: any,
    agentCompany: AgentCompany,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query TriggerSendBuyingOfferEmailQuery(
          $buyingOfferId: String!
          $emailData: EmailData!
          $agentCompany: AgentCompany!
        ) {
          buyingOfferSendEmail(
            buyingOfferId: $buyingOfferId
            emailData: $emailData
            agentCompany: $agentCompany
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        buyingOfferId,
        emailData,
        agentCompany
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.buyingOfferSendEmail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  sendCustomerPortalLinkEmail$(
    buyingOfferId: string,
    emailData: any,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query BuyingOfferSendCustomerPortalLinkEmail(
          $buyingOfferId: String!
          $emailData: EmailData!
        ) {
          buyingOfferCustomerPortalLinkSendEmail(
            buyingOfferId: $buyingOfferId
            emailData: $emailData
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        buyingOfferId,
        emailData,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.buyingOfferCustomerPortalLinkSendEmail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  getBuyingOfferCustomerPortalLinkEmailVariables$(
    buyingOfferId: string,
    handInOffer: boolean,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetBuyingOfferCustomerPortalLinkEmailVariables(
          $buyingOfferId: String!
          $handInOffer: Boolean!
        ) {
          buyingOfferCustomerPortalLinkEmailVariables(
            buyingOfferId: $buyingOfferId
            handInOffer: $handInOffer
          ) {
            customerPortalLink
            propertyAddress
            signature
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        buyingOfferId,
        handInOffer,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.buyingOfferCustomerPortalLinkEmailVariables),
    );
  }

  getBuyingOfferOwnerPortalLinkEmailVariables$(buyingOfferId: string): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetBuyingOfferOwnerPortalLinkEmailVariables(
          $buyingOfferId: String!
        ) {
          buyingOfferOwnerPortalLinkEmailVariables(
            buyingOfferId: $buyingOfferId
          ) {
            customerPortalLink
            propertyAddress
            signature
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        buyingOfferId,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.buyingOfferOwnerPortalLinkEmailVariables),
    );
  }

  sendSellerCustomerPortalLink$(
    buyingOfferId: string,
    emailData: any,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query SendSellerCustomerPortalLink(
          $buyingOfferId: String!
          $emailData: EmailData!
        ) {
          buyingOfferOwnerPortalLinkSendEmail(
            buyingOfferId: $buyingOfferId
            emailData: $emailData
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        buyingOfferId,
        emailData,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.buyingOfferOwnerPortalLinkSendEmail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  getBuyingOfferEmailVariables$(
    buyingOfferId: string,
    documentUrl: string,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetBuyingOfferEmailVariables(
          $buyingOfferId: String!
          $documentUrl: String!
        ) {
          buyingOfferEmailVariables(
            buyingOfferId: $buyingOfferId
            documentUrl: $documentUrl
          ) {
            propertyAddress
            documentUrl
            signature
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        buyingOfferId,
        documentUrl,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data?.buyingOfferEmailVariables),
    );
  }

  createBuyingOfferDocument$(
    objectId: string,
    buyingOfferId: string,
    agentCompany: AgentCompany,
  ): Observable<string> {
    return this.apollo
      .query({
        query: gql`
          query CreateBuyingOfferDocument(
            $objectId: String!
            $buyingOfferId: String!
            $agentCompany: AgentCompany!
          ) {
            buyingOfferDocument(
              objectId: $objectId
              buyingOfferId: $buyingOfferId
              agentCompany: $agentCompany
            ) {
              url
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          objectId: objectId,
          buyingOfferId: buyingOfferId,
          agentCompany: agentCompany,
        },
      }).pipe(
        first(),
        map((result: any) => result.data.buyingOfferDocument.url),
      );

    // return this.getBuyersAndSellersFromBuyingOfferById$(objectId, buyingOfferId).pipe(
    //   switchMap((buyingOffer) => {
    //     let buyersData;
    //     let sellersData;

    //     if (buyingOffer.buyerType === CustomerType.Business) {
    //       buyersData = buyingOffer.businessBuyer?.customerData.customers.map(
    //         ({ id, ...other }) => ({ ...other, customerId: id })
    //       ) ?? [];
    //     } else {
    //       buyersData = buyingOffer.buyers ?? [];
    //     }

    //     if (buyingOffer.sellerType === CustomerType.Business) {
    //       sellersData = buyingOffer.businessSeller?.customerData.customers.map(
    //         ({ id, ...other }) => ({ ...other, customerId: id })
    //       ) ?? [];
    //     } else {
    //       sellersData = buyingOffer.sellers ?? [];
    //     }

    //     return getBuyingOfferDocumentUrl(buyersData, sellersData);
    //   })
    // )
  }

  compressFiles$(objectId: string, buyingOfferId: string, filePaths: string[], filenames: string[]): Observable<string> {
    return this.apollo.query({
      query: gql`
        query CompressBuyingOfferFiles(
          $objectId: String!
          $buyingOfferId: String!
          $filePaths: [String!]!
          $filenames: [String!]!
        ) {
          compressBuyingOfferFiles(
            objectId: $objectId
            buyingOfferId: $buyingOfferId
            filePaths: $filePaths
            filenames: $filenames
          )
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        buyingOfferId: buyingOfferId,
        filePaths: filePaths,
        filenames: filenames,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.compressBuyingOfferFiles),
    );
  }

  orderBuyingContract$(buyingOfferId: string, objectId: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation OrderBuyingContract($buyingOfferId: String!, $objectId: String!) {
          orderBuyingContract(buyingOfferId: $buyingOfferId, objectId: $objectId)
        }
      `,
      variables: {
        buyingOfferId: buyingOfferId,
        objectId: objectId,
      },
    }).pipe(
      map((result: any) => result.data.orderBuyingContract)
    );
  }

  copyOfferToProperty$(buyingOfferId: string, destinationPropertyId: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation CopyBuyingOfferToAnotherProperty($buyingOfferId: String!, $destinationPropertyId: String!) {
          buyingOfferCopyToProperty(buyingOfferId: $buyingOfferId, destinationPropertyId: $destinationPropertyId)
        }
      `,
      variables: {
        buyingOfferId,
        destinationPropertyId,
      },
    }).pipe(
      map((result: any) => result.data.buyingOfferCopyToProperty)
    );
  }
}

export const realtyPriceFragment = gql`
  fragment realtyPriceFragment on RealtyPrice {
    purchase_price {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    rent {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    furniture_hire {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    parking {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    garage {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    operating {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    heating {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    hot_water {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    cooling {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    maintenance_funds {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    elevator {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
    others {
      currency
      net
      gross
      vat_absolute
      vat_percentage
    }
  }
`;
