import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { DynamicFieldConfig } from './types';

@Injectable({ providedIn: 'root' })
export class DynamicFormService {
  constructor(private readonly _fb: FormBuilder) { }

  buildFormFromConfig(fields: DynamicFieldConfig[]): FormGroup {
    const form = this._fb.group({});

    for (const field of fields) {
      if (field.type === 'multiselect') {
        form.addControl(field.key, this._fb.control([]));
      } else {
        form.addControl(field.key, this._fb.control(''));
      }
    }

    return form;
  }

  buildGroupConfigForm(): FormGroup {
    return this._fb.group({
      key: ['', Validators.required],
      label: ['', Validators.required],
      fields: this._fb.array([]),
    });
  }

  buildFieldConfigForm(): FormGroup {
    return this._fb.group({
      key: ['', Validators.required],
      label: ['', Validators.required],
      type: ['text', Validators.required],
      placeholder: [''],
      options: this._fb.array([]),
    });
  }

  buildFieldOptionForm(option: { value: string; label: string }): FormGroup {
    return this._fb.group({
      value: [option.value, Validators.required],
      label: [option.label, Validators.required],
    });
  }

  generateKey(label: string): string {
    if (!label) {
      return '';
    }
    const germanReplacements: { [key: string]: string } = {
      ä: 'ae',
      ö: 'oe',
      ü: 'ue',
      ß: 'ss',
      Ä: 'Ae',
      Ö: 'Oe',
      Ü: 'Ue',
    };

    let normalizedLabel = label.replace(/ä|ö|ü|ß|Ä|Ö|Ü/g, (match) => germanReplacements[match]);

    return normalizedLabel
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '_') // Replace non-alphanumeric characters with underscores
      .replace(/_+/g, '_') // Replace multiple underscores with a single underscore
      .replace(/^_|_$/g, ''); // Remove leading/trailing underscores
  }
}