import { CustomerPortalAgent } from '@domains/customer-portal';
import { Address } from 'src/app/common-models/address';
import { ImageInfo } from "@domains/handover-protocol";
import { Documents } from "../exclusive-agreement";
import { CustomerType } from "../../common-components/customer-type-selector/model";
import { CustomerWithContact } from "@domains/customer";
import { BusinessCustomerWithCustomersDetails } from "@domains/business-customer";
import { DynamicFieldConfig } from 'src/app/common-components/dynamic-form/types';

// TODO: see if null can be replaced by optional

export interface ObjectAgent {
  id?: number;
  fullname?: string;
  email?: string;
}

export interface ObjectsCountByAgent {
  name: string;
  count: number;
}

export interface SearchObjectsResult {
  name: string;
  objects: Object[];
}

export interface RentalOfferSettings {
  allowPublicAccess: boolean;
  allAskForPep: boolean;
  allSubmitRentalOffer: boolean;
  mustCompleteCreditCheck: boolean;
  mustFillCustomerQuestionnaire: boolean;
  mustFillCustomerQuestionnaireOnly: boolean;
  mustHaveUploads: MustHaveUploads[];
}

export interface MustHaveUploads {
  title: string;
  description: string;
  mandatory: boolean;
}

export interface BuyingOfferSettings {
  allAskForSufficientFinance: boolean;
  allAskForPep: boolean;
  allAskForMoneyLaundering: boolean;
  allSubmitBuyingOffer: boolean;
  mustHaveUploads: MustHaveUploads[];
}

export interface GeneralObjectSettings {
  accessToFinanceCalculator: boolean;
  accessToAllDocuments: boolean;
  documents: any[];
}

export interface ObjectSettings {
  objectId: string;
  agent: CustomerPortalAgent;
  buyingOfferSettings: BuyingOfferSettings;
  rentalOfferSettings: RentalOfferSettings;
  viewingPassSettings: ViewingPassSettings;
  generalSettings: GeneralObjectSettings;
}

export interface ViewingPassSettings {
  allSubmitViewingPass: boolean;
}

export interface Object {
  id: string;
  userId: string;
  creationSource: string;
  objectType: ObjectType | null;
  orderType: OrderType | null;
  objectStatus: ObjectStatus;
  address: Address;
  externalObjectNumber?: string;
  externalObjectDisplayNumber?: string;
  externalObjectType?: string;
  externalState?: string;
  externalSystem?: string;
  propertyType?: string;
  propertyTypeNotes?: string;
  shareInOwnership?: string;
  cadastreLabel?: string;
  parkingSlotsLabel?: string[];
  complementLabel?: string[];
  description?: string;
  usageRoule?: boolean;
  movingInDate?: Date;
  movingInDateDetails?: string;
  rooms?: number;
  roomsAndArea?: ObjectRoomsAndArea;
  condition?: Condition;
  inventoryList?: InventoryList[];
  propertyNotes?: PropertyNotes[];
  propertyAttributes?: PropertyAttributes;
  energyPass?: PropertyEnergyPass;
  price: number;
  priceDetails?: PriceDetails;
  realtyPrice?: RealtyPrice;
  defects?: Defects;
  usageBoundCosts?: UsageBoundCosts;
  preListingData?: PreListingData;
  valuationLightData?: ValuationLightData;
  additionalInformation?: AdditionalInformation;
  locationScoreData?: LocationScoreData;
  dossierLink?: DossierLink;
  preListingReport?: PreListingReport;
  creationDate?: Date;
  title?: string;
  agent?: ObjectAgent;
  citywalxLink?: CitywalxLink;
  commissionBuy?: CommissionBuy;
  commissionRent?: CommissionRent;
  propertyDetails?: PropertyDetails;
  hasEntriesInLandRegister?: boolean;
  entriesInLandRegister?: string;
  rentalSpecifications?: RentalSpecifications;
  documents?: ObjectDocument[];
  neededDocuments?: Documents;
  images?: ImageInfo[]
  modifiedInSystem: boolean;
  ownerType?: CustomerType;
  owners: Owner[];
  businessOwner?: {
    customerId: string;
    customerData: BusinessCustomerWithCustomersDetails;
  }
  platformExports?: {
    maklerSalzburgIsActive: boolean;
  }
  /** Custom defined property fields */
  customContent: any;
}

export interface Owner extends CustomerWithContact {
  customerId: string;
}

export interface PropertyEnergyPass {
  energyPassAvailable: string;
  energyPassResponsible: string;
  energyPassDelivery: string;
  hwb: string;
  hwbClass: ENERGYPASSCLASS;
  fGEE: string;
  fgeeClass: ENERGYPASSCLASS;
  validUntil: string;
}

export interface PropertyAttributes {
  beaconing: Beaconing;
  heating: Heating;
  floors: Floors;
  structure: Structure;
  connections: Connections;
}

export interface Structure {
  prefab: boolean;
  solid: boolean;
  brick: boolean;
  wood: boolean;
  ferroconcrete: boolean;
}

export interface Connections {
  electricity: boolean;
  highVoltage: boolean;
  gas: boolean;
  water: boolean;
  fountain: boolean;
  teleheating: boolean;
  canal: boolean;
  phone: boolean;
  tvCabel: boolean;
  tvSat: boolean;
  internet: boolean;
}

export interface Heating {
  selfContainedCentralHeating: boolean; // Etagenheizung
  floorHeating: boolean; // Fußbodenheizung
  chimney: boolean; // Kamin
  teleheating: boolean; // Fernwärme
  centralHeating: boolean; // Zentralheizung
}

export interface Floors {
  floorboards: boolean; // Dielen
  screed: boolean; // Estrich
  tiles: boolean; // Fliesen
  syntheticFloor: boolean; // Kunststoffboden
  linoleum: boolean; // Linoleum
  parquet: boolean; // Parkett
  carpet: boolean; // Teppichboden
  terracotta: boolean; // Terrakotta
  accessFloor: boolean; // Doppelboden
  finishedParquet: boolean; // Fertigparkett
  granite: boolean; // Granit
  laminate: boolean; // Laminate
  marble: boolean; // Marmor
  stone: boolean; // Steinboden
  carpetTiles: boolean; // Teppichfliesen
}

export interface Beaconing {
  alternative: boolean; // Alternativ
  electro: boolean; // Elektro
  teleheating: boolean; // Fernwärme
  gas: boolean; // Gas
  coal: boolean; // Kohle
  oil: boolean; // Öl
  solar: boolean; // Solarenergie
  thermalPower: boolean; // Blockheizkraftwerk
  geothermics: boolean; // Erdwärme
  fluidGas: boolean; // Flüssiggas
  wood: boolean; // Holz
  airHeatPump: boolean; // Luftwärmepumpe
  pellets: boolean; // Pellets
  waterElectro: boolean; // Wasser-Elektro
}

export interface InventoryList {
  title: string;
  notes: string;
}

export interface PropertyNotes {
  title: string;
  notes: string,
}

export interface Condition {
  overall: CONDITION;
  floor: CONDITION;
  roof: CONDITION;
  windows: CONDITION;
  installer: CONDITION;
  electric: CONDITION;
  reconstruction: boolean;
  reconstructionPlan: string;
  reserveCoverage: boolean;
  hiddenFlaws: boolean;
  hiddenFlawsNotes: string;
  offBookCharges: boolean;
  offBookChargesNotes: string;
}

export interface RealtyPrice {
  purchase_price: RealtyPriceItem, //Kaufpreis
  rent: RealtyPriceItem, // Miete
  furniture_hire: RealtyPriceItem, //Möbelmiete
  parking: RealtyPriceItem, //Parkplatz
  garage: RealtyPriceItem, //Garage
  operating: RealtyPriceItem, //Betriebskosten
  heating: RealtyPriceItem, //Heizkosten
  hot_water: RealtyPriceItem, //Warmwasser
  cooling: RealtyPriceItem, //Kühlung
  maintenance_funds: RealtyPriceItem, //Reparaturrücklage
  elevator: RealtyPriceItem, //Lift
  others: RealtyPriceItem,
}

export interface RealtyPriceItem {
  currency: string;
  net: number;
  gross: number;
  vat_absolute: number;
  vat_percentage: number;
}

export interface ObjectDocument {
  id: string;
  title: string;
  description: string;
  storageUrl: string;
  type: string;
  documentFrom: Date;
}

export interface AgentDataCompact {
  firstname: string;
  lastname: string;
  email: string;
}

export interface PropertySharing {
  id: string;
  userId: string;
  user: AgentDataCompact;
}

export interface PropertyActivityFile {
  url: string;
  title: string;
  description: string;
}

export interface PropertyActivity {
  id: string;
  objectId: string;
  type: string;
  source: string;
  description: string;
  createdBy: string;
  createdByAgent: AgentDataCompact | null;
  customers: Array<CustomerWithContact | null>;
  customerIds: string[];
  activityFile: PropertyActivityFile;
  createdAt: string;
}

export interface RentalSpecifications {
  rentDurationDetails: string;
  maximumRentalPeriod: number;
  unlimitedRentalPeriod: boolean;
  waiverOfCancellationPeriod: number;
  drafterOfDeedsCosts: string;
  tenancyLaw: boolean;
  tenancyLawApplication: string;
  hasCommercialUseAllowance: boolean;
  hasSubLeasingAllowance: boolean;
  hasLeasingTerm: boolean;
  contractValidUntil: string;
  price: RentPrice
  valorization: Valorization;
  rentalDeposit: RentalDeposit;
  singlePayment: SinglePayment;
  misc: string;

}

export interface SinglePayment {
  singlePayment: boolean;
  singlePaymentInfo: string;
  singlePaymentAmount: number;
}

export interface RentalDeposit {
  depositAmount: number;
  depositMethod: string;
}

export interface RentPrice {
  rent: number;
  vat: number;
}

export interface Valorization {
  hasValorization: boolean;
  vpi: string;
}

export interface CommissionRent {
  commissionCalculationType: string;
  commissionMonths: number;
  commissionPercentageSpecial: number;
  commission: number;
  vat: number;
}

export interface PropertyDetails {
  use: string;
  movingInDate: string;
  rulesOfUse: boolean;
}

export interface UsageBoundCosts {
  givenLater: boolean;
  costs: number;
  operatingCostsTax: boolean;
  electricity: boolean;
  gas: boolean;
  heating: boolean;
  telecommunication: boolean;
  water: boolean;
  other: boolean;
  otherDetails: string;
}

export interface Defects {
  defects: boolean;
  defectsDetails: string;
}

export interface PriceDetails {
  customerExpectedPrice: number;
  reserve: number;
  operatingCostStipulation: number;
  addedValueTax: boolean;
  startingPrice: number;
  bottomUpPrice: boolean;
  minPrice: number;
  priceUponRequest: boolean;
  priceToBeDiscussed: boolean;
  coveredInPrice: string;
  otherConditions: string;
  operatingCosts: number;
  operatingCostsTax: boolean;
  otherCharges: boolean;
  otherChargesDetails: string;
}

export interface CommissionBuy {
  commissionCalculationType: string;
  commissionPercentage: number;
  commission: number;
  vat: number;
}

export interface ObjectRoomsAndArea {
  livingArea?: AreaItem;
  baseArea?: AreaItem;
  usableArea?: AreaItem;
  generalRoomsInformation?: GeneralRoomsInformation
  toilets?: AreaItem
  bathRooms?: AreaItem
  storeRooms?: AreaItem
  garden?: AreaItem
  basement?: AreaItem
  balcony?: AreaItem
  loggia?: AreaItem
  terrace?: AreaItem
  parking?: AreaItem
  garage?: AreaItem
  otherRooms?: [{
    title?: number;
    area?: number;
    areaCa?: boolean;
  }]
}

export interface GeneralRoomsInformation {
  count?: number;
  floorAmount: number;
  floor: number;
  floorDescription?: string;
  elevator: boolean;
}

export interface AreaItem {
  roomCount?: number;
  area?: number;
  areaCa?: boolean;
}

export interface CitywalxLink {
  id: string;
  address: string,
  expire: string;
  link: string;
}

export interface ObjectCreation {
  creationSource: string;
  objectType: ObjectType | null;
  orderType: OrderType | null;
  objectStatus: ObjectStatus;
  roomsAndArea?: ObjectRoomsAndArea;
  price?: number;
  address: Address;
  preListingData?: PreListingData;
  additionalInformation?: AdditionalInformation;
}

export interface PreListingData {
  hasValuationLight: boolean;
  hasValuationFull: boolean;
  hasLocationScore: boolean;
  hasPOIs: boolean;
  hasAccessibility: boolean;
  hasDossier: boolean;
  hasContaminatedSites: boolean;
  hasHORAPass: boolean;
  hasMaps: boolean;
}

export type ObjectType =
  | 'house'
  | 'flat'
  | 'premises'
  | 'businessObject'
  | 'specialObject';
export type OrderType = 'buy' | 'rent';

export type ObjectStatus = 'active' | 'completed' | 'draft' | 'deleted' | null;

export interface ValuationLightData {
  confidence: string;
  currency: string;
  valueRange: {
    lower: number;
    upper: number;
  };
}

export interface AdditionalInformation {
  constructionYear: number;
  constructionYearCa?: boolean;
  cadastralCommunity?: string;
  shares?: string;
  entryNumber?: string;
  parcelNumber?: string;
  parcelNumbers?: string[];
}

export interface LocationScoreData {
  health: number;
  leisure: number;
  family: number;
  shopping: number;
  catering: number;
}

export interface DossierLink {
  id: string;
  url: string;
}

export interface PreListingReport {
  url: string;
  creationDate?: Date;
}

export interface ValuationFullBuyData {
  confidence: string;
  currency: string;
  value: number;
  valueRange: {
    lower: number;
    upper: number;
  };
  salePrice: number;
  salePriceRange: {
    lower: number;
    upper: number;
  };
}

export interface ValuationFullRentData {
  confidence: string;
  currency: string;
  value: number;
  valueRange: {
    lower: number;
    upper: number;
  };
  rentNet: number;
  rentNetRange: {
    lower: number;
    upper: number;
  };
  rentGross: number;
  rentGrossRange: {
    lower: number;
    upper: number;
  };
}

export interface CadastralCommunity {
  KGNr: string;
  KGName: string;
  PGNr: string;
  PolName: string;
}

export interface UserMappings {
  userId: string;
  mapping: MappingTypes[];
}

export interface MappingTypes {
  field: string;
  fieldMapping: string;
  label?: string;
  type?: string;
  permittedValues?: PermittedValues[];
}

export interface PermittedValues {
  key: string;
  value: string;
}

export interface UpdateUserMapping {
  modifications: MappingTypes[];
}

export enum CONDITION {
  VERYGOOD = 'VERYGOOD',
  GOOD = 'GOOD',
  MEDIUM = 'MEDIUM',
  BAD = 'BAD',
  NOTAVILABLE = 'NOTAVAILABLE'
}

export type ConditionType = 'VERYGOOD' | 'GOOD' | 'MEDIUM' | 'BAD' | 'NOTAVAILABLE' | null;

export enum ENERGYPASSCLASS {
  APlusPlus = 'APlusPlus',
  APlus = 'APlus',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  NOTAVAILABLE = 'NOTAVAILABLE',
}

export interface PropertyExtensionConfigGroup {
  label: string;
  key: string;
  fields: DynamicFieldConfig[];
}

export interface PropertyExtensionConfig {
  id: string;
  individualGroups: PropertyExtensionConfigGroup[];
  teamGroups: PropertyExtensionConfigGroup[];
}

export interface CommonPropertyExtensionConfig {
  groups: PropertyExtensionConfigGroup[];
}

export interface OnOfficeDocumentConfig {
  documentTypeOptions: Array<{ value: string; label: string; category: string }>;
  documentAttributeOptions: Array<{ value: string; label: string }>;
}
