import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { first, map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client';

import { ContractPartner, ContractPartnerWithCustomersDetails } from './contract-partner.model';

@Injectable({ providedIn: 'root' })
export class ContractPartnerApiFacade {
  constructor(private readonly apollo: Apollo) { }

  createContractPartner$(data: ContractPartner): Observable<ContractPartner> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation CreateContractPartner($customer: CreateContractPartner!) {
            contractPartner(customer: $customer) {
              id
              type
              companyNumber
              companyRegister
              uidNumber
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              customers
            }
          }
        `,
        variables: {
          customer: data,
        },
      })
      .pipe(map((result: any) => result.data.contractPartner));
  }

  searchContractPartner$(companyNumber: string): Observable<ContractPartner[]> {
    return this.apollo.query({
      query: gql`
        query GetContractPartners($companyNumber: String) {
          searchContractPartners(companyNumber: $companyNumber) {
            id
            type
            companyNumber
            companyRegister
            uidNumber
            contact {
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
              phone
              email
            }
            customers
          }
        }
      `,
      variables: {
        companyNumber: companyNumber ?? '',
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data.searchContractPartners)
    );
  }

  getContractPartnersByType$(type: string): Observable<ContractPartner[]> {
    return this.apollo.query({
      query: gql`
        query GetContractPartnersByType($type: String) {
          contractPartnersByType(type: $type) {
            id
            type
            companyNumber
            companyRegister
            uidNumber
            contact {
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
              phone
              email
            }
            customers
          }
        }
      `,
      variables: {
        type: type ?? '',
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data.contractPartnersByType)
    );
  }

  getAllCustomers$(): Observable<ContractPartner[]> {
    return this.apollo.query({
      query: gql`
        query GetContractPartners {
          contractPartners {
            id
            type
            companyNumber
            companyRegister
            uidNumber
            contact {
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
              phone
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data.contractPartners)
    );
  }

  getCustomerById$(id: string): Observable<ContractPartnerWithCustomersDetails> {
    return this.apollo.query({
      query: gql`
        query GetContractPartnerDetails($customerId: String!) {
          contractPartner(id: $customerId) {
            id
            type
            companyNumber
            companyRegister
            uidNumber
            contact {
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
              email
              phone
            }
            customers {
              id
              title
              firstname
              lastname
              birthdate
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                email
                phone
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId: id,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data.contractPartner)
    );
  }

  deleteContractPartner(contractPartnerId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeleteContractPartner($contractPartnerId: String!) {
          deleteContractPartner(contractPartnerId: $contractPartnerId)
        }
      `,
      variables: {
        contractPartnerId,
      },
    }).pipe(
      map((result: any) => result.data.deleteContractPartner)
    );
  }
}
