import { Environment } from './environment-config';

export const environment: Environment = {
  production: true,
  enablePWA: true,
  backendBaseUrl: 'https://plattform.propup.at/api/graphql',
  documentServiceBaseUrl: 'https://backend.propup.at/object-service/',
  customerServiceBaseUrl: 'https://backend.propup.at/customer-service/',
  propnowRemaxUrl: 'https://dave.remax.at/dashboard/search?idp=propup&property=',
  propnowUrl: 'https://app.propnow.com/dashboard/search?idp=propup&property=',
  SENTRY_ENVIRONMENT: 'production',
  customerPortalUrl: 'https://app.propup.at/',
  staticToggles: {
    enableLoggingTracing: true,
    showSimplifiedAddressPage: true,
    hopShowDummyDocumentGenerationButton: false,
    showImageManagement: true,
    showMarketplacePage: false,
    showNewHopScreen: true,
    showValuationLight: true,
    showValuationFull: false,
    showLocationScore: true,
    showPOIs: true,
    showDossier: false,
    showAccessibility: true,
    showSimplifiedFAGG: false,
    showAdditionalEmails: true,
    showContaminatedSites: true,
    showHORAPass: true,
    showMaps: true,
    enableTeams: true,
    showBanner: true,
    showDownloadDocuments: true,
    showRentalContracts: true,
    showCustomerPortal: true,
    showCreditCheck: false,
    showPublicCustomerPortalLink: true,
    showCustomerPortalBuyingOffer: true,
    showExposeGenerator: true,
    showSendCustomerLinkPortal: true,
    showCustomerPortalQuestionnaire: true,
    showCustomerPortalFeedback: true,
    showOnOffice: false,
    showObjectImages: true,
    showPropertiesDuplicationCheck: true,
    showOfficeArea: false,
    showExtendedObjectCreation: true,
    showVPAddDocs: true,
    showPlainAgreement: true,
    showPropertySharing: true,
    showPropertyActivities: true,
    showMicrosoft: true,
    showCustomerPortalOwnerSigning: true,
    showPlatformExport: false,
    showBuyingContracts: true,
    showPROPUPValuation: false,
    showViewingPassCustomerPortal: false,
    showUpdateOnOfficeProperty: false,
    showPropertySettings: false,
    showSelfDeclarationOnlyProcess: true,
    showBulkInvite: true,
  },
};
